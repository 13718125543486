<template>
<v-card class="mt-3 pa-1" :loading="isLoadingModules">
    <v-card-title class="pb-1">
        <v-icon class="mr-2">settings</v-icon>
        Shop Module Configs
    </v-card-title>
    <div v-if="!isLoadingModules">
        <div cols="12" 
            class="ma-2 "
            v-for=" userModule in availableModules" :key="userModule.key"
        >
            <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="btn-border text-left pl-0"
                        outlined
                        width="100%"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <v-card-text>
                            {{ userModule.title }}
                        </v-card-text>
                        <v-switch
                            v-model="userModule.enabled"
                            color="blue darken-1"
                            @click="toggleModule(userModule)"
                        ></v-switch>
                    </v-btn>
                </template>
                <span>
                    {{ userModule.description }}
                    <div v-if="userModule.enabled && userModule.installed_at != null">
                        <v-badge 
                            color="blue"
                            :content="userModule.installed_at"
                        ></v-badge>
                    </div>
                </span>
            </v-tooltip>
        </div>
        <v-overlay v-if="openModuleSwitchConfirmation">
            <v-card
                light
                class="pa-2"
                :disabled="saving"
            >
                <v-icon 
                    class="float-right"
                    @click="leaveOpenConfigmrationDialog()">
                    close
                </v-icon>
                <v-card-title>
                    <span v-if="currentModule.enabled">
                        Are you sure you want to <v-icon>check_circle</v-icon> TURN ON {{ currentModule.name }}?
                    </span>
                    <span v-else>
                        Are you sure you want to <v-icon>unpublished</v-icon> TURN OFF {{ currentModule.name }}?
                    </span>   
                </v-card-title>
                <v-card-subtitle>
                    * This acitivation will not adding any new charge to current user plan.
                    If you want to add charges to the user, please use custom plan instead.
                </v-card-subtitle>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="buttons">
                        <v-btn color="primary" @click="saveModuleSetting()">Yes</v-btn>
                        <v-btn class="ml-3" @click="leaveOpenConfigmrationDialog()">No</v-btn>
                    </div>
                </v-card-actions>
            </v-card>
        </v-overlay>
    </div>
    <v-card-title v-else class="pb-1">
        <v-card-text class="pa-0">Loading Available Modules...</v-card-text>
    </v-card-title>
</v-card>
</template>
<script>
import { mapState } from "vuex";
import { FETCH_AVAILABLE_MODULES, UPDATE_MODULE_BY_USER_ID } from "@/store/actions.type";

export default {
    name: "ModuleSwitches",
    data: function() {
        return {
            saving: false,
            currentModule: null,
            openModuleSwitchConfirmation: false,
            isLoadingModules: true
        };
    },
    created() {
        if (this.currentShop != null) {
            let shopUserId = this.currentShop.user_id
            this.$store.dispatch(`users/${FETCH_AVAILABLE_MODULES}`, shopUserId)
        }
    },
    computed: {
        ...mapState('users', [ "availableModules" ]),
        ...mapState('shops', [ "currentShop" ]),
    },
    watch: {
        async currentShop(newValue, oldValue) {
            if (newValue != oldValue && newValue != null) {
                if (this.currentShop != null) {
                    let shopUserId = this.currentShop.user_id
                    await this.$store.dispatch(`users/${FETCH_AVAILABLE_MODULES}`, shopUserId);
                    this.isLoadingModules = false;
                }
            }
        }
    },
    methods: {
        toggleModule(module) {
            this.openModuleSwitchConfirmation = true;
            this.currentModule = module;
        },
        leaveOpenConfigmrationDialog(){
            this.openModuleSwitchConfirmation = false;
            this.currentModule.enabled = !this.currentModule.enabled;
            this.currentModule = null;
        },
        saveModuleSetting() {
            this.saving = true;
            if (this.currentModule != null) {
                let shopUserId = this.currentShop.user_id
                this.$store.dispatch(`users/${UPDATE_MODULE_BY_USER_ID}`, { userId: shopUserId, module: this.currentModule})
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.saving = false;
                    this.openModuleSwitchConfirmation = false;
                    this.currentModule = null;
                });
            } 
        }
    },
}
</script>
<style scoped>
.btn-border {border-color: #ccc;}
</style>