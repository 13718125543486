<template>
  <v-card color="#f8f8f8" class="connection-widget ml-3 mr-3 mb-3">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title
          class="title"
          v-if="parentShop.store_type != 'source'"
        >
          {{ shopConnection.source_store.store_domain }}
        </v-list-item-title>
        <v-list-item-title class="title" v-else>
          {{ shopConnection.destination_store.store_domain }}
        </v-list-item-title>
        <v-list-item-subtitle>
          connected at: {{ shopConnection.created_at }}
        </v-list-item-subtitle>
        <v-card-text>
          <v-btn
            x-small
            outlined
            class="mr-3"
            @click="resyncProducts()"
          >
            <v-icon small>mdi-sync</v-icon>
          </v-btn>
          Num. of products mapped
          <span class="sync-number">{{
            shopConnection.total_products_mapped
          }}</span>
        </v-card-text>
      </v-list-item-content>
      <v-list-item-action>
        <v-icon @click="editShop(shopConnection)">mdi-eye</v-icon>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import {
  FETCH_CURRENT_SHOP,
  FETCH_CURRENT_SHOP_CONNECTIONS,
  RESYNC_MAPPING_BY_CONNECTION,
} from "@/store/actions.type";
import {
  SET_CURRENT_SHOP_CONNECTIONS,
  SET_LOADING,
} from "@/store/mutations.type";
import { mapGetters } from "vuex";

export default {
  name: "ShopsConnectionWidget",
  props: ["shopConnection", "parentShop"],
  data: function() {
    return {
      toggle_none: null,
    };
  },
  computed: {
    ...mapGetters("shops", ["currentShopConnections"]),
  },
  methods: {
    editShop(shopConnection) {
      let shop = shopConnection.source_store;
      if (this.parentShop.store_type != "destination") {
        shop = shopConnection.destination_store;
      }
      this.fetchShopData(shop.id);
      this.$router.push({ name: "SingleStorePage", params: { id: shop.id } });
    },
    fetchShopData(shopId) {
      this.$store.commit(`shops/${SET_LOADING}`, true);
      this.$store.commit(`shops/${SET_CURRENT_SHOP_CONNECTIONS}`, {});
      this.$store.dispatch(`shops/${FETCH_CURRENT_SHOP}`, shopId).then(() => {
        this.$store
          .dispatch(`shops/${FETCH_CURRENT_SHOP_CONNECTIONS}`, shopId)
          .finally(() => {
            console.log('fetch current shop connections done');
            this.$store.commit(`shops/${SET_LOADING}`, false);
          });
      });
    },
    resyncProducts() {
      let confirmed = confirm("Are you sure you want to resync all mapped products!");
      if (confirmed) {
        this.$store.dispatch(`shops/${RESYNC_MAPPING_BY_CONNECTION}`, this.shopConnection.id)
            .then((data) => {
              console.log(data);
              if (data.success) {
                alert('Full resync started!');
              } else {
                alert('Full resync request failed...');
              }
            })
            .catch(() => {
              alert('Full resync request failed...');
            });
      }
    }
  },
};
</script>

<style lang="scss">
.connection-widget {
  .sync-number {
    margin-left: 5px;
    border-radius: 3px;
    padding: 5px 9px;
    background-color: #777;
    color: #fff;
  }
}
</style>
