<template>
<span class="fetch-product-trigger">
    <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
            <v-icon
                color="orange"
                right
                v-bind="attrs"
                v-on="on"
                @click="fetchProductsDialog = true"
            >
                mdi-database-sync
            </v-icon>
        </template>
        <span>Fetch Store Products to Syncio System</span>
    </v-tooltip>
    <v-dialog
        width="500"
        v-model="fetchProductsDialog"
    >
        <v-card>
            <v-card-title>Do you want to refreshing the products data?</v-card-title>
            <v-card-text>
                All exisiting product data in Syncio will be deleted and refetch to ensure we have the latest copies from this store.
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="btn default" @click="fetchProductsDialog = false">Cancel</v-btn>
                <v-btn class="btn primary" @click="requestFetchShopProducts()">Submit</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</span>
</template>
<script>
import { FETCH_STORE_PRODUCTS } from "@/store/actions.type";
import { mapState } from 'vuex';

export default {
    name: "FetchProductTrigger",
    data: function() {
        return {
            fetchProductsDialog: false,
        };
    },
    computed: {
        ...mapState('shops', [ 'currentShop' ]),
    },
    methods: {
        requestFetchShopProducts() {
            this.$store.dispatch(`shops/${FETCH_STORE_PRODUCTS}`)
                .then((data) => {
                    if (data.success) {
                        alert(data.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.fetchProductsDialog = false;
                });
            return;
        }
    },
}
</script>
<style lang="scss">
    .fetch-product-trigger {
        float: right;
    }
</style>