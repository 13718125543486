<template>
  <v-container>
    <v-row>
      <v-col cols="4">
        <v-card :disabled="loading" :loading="loading" class="mx-auto">
          <v-card-title>
            <v-img v-show="currentShop.platform === 'woocommerce'" max-width="30" class="mr-3" src="@/assets/images/woo_logo.png"></v-img>
            <v-img v-show="currentShop.platform === 'shopify'" max-width="30" class="mr-3" src="@/assets/images/shopify_logo.png"></v-img>
            <span>{{ currentShop.store_domain }}</span>
          </v-card-title>
          <v-container>
            <v-chip class="mr-5" small label>
              ID
            </v-chip>
            {{ currentShop.id }}
          </v-container>
          <v-list-item>
            <v-list-item-icon>
              <v-icon small>account_circle</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-uppercase">
              <div>
                <v-btn x-small outlined color="blue" disabled>
                  {{ currentShop.user_id }} <v-icon class="ml-3" small>input</v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="store-type">
            <v-list-item-icon>
              <v-icon small>mdi-store</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-uppercase" :class="{
              destination: currentShop.store_type === 'destination',
              source: currentShop.store_type === 'source',
            }">{{ currentShop.store_type }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon small>mdi-link</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ currentShop.identifier }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon small>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-content>{{ currentShop.email }}</v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon small>mdi-package-variant-closed</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <strong>
                Total # of products:
                <fetch-product-trigger v-if="currentShop.store_type != 'destination'"></fetch-product-trigger>
              </strong>
              <store-product-counter></store-product-counter>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon small>mdi-clock</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <strong>Installed at:</strong>
              {{ currentShop.created_at }}
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-icon>
              <v-icon small>account_tree</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <strong>
                Current Processing Group:
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-on="on" v-bind="attrs" small>help_outline</v-icon>
                  </template>
                  <span>Store has been assigned to the following processing group in the network.</span>
                </v-tooltip>
              </strong>
              <div class="pa-1 mb-7">
                <template v-if="currentShop.assigned_server_group != null">
                  <v-badge color="cyan lighten-1" :content="currentShop.assigned_server_group.group || currentShop.assigned_server_group.last_ping"></v-badge>
                  <v-btn small color="danger" @click="removeProcessingGroup(currentShop.id)" style="transform: translateY(30px);">Delete</v-btn>
                </template>
                <template v-else>
                  <v-badge color="grey lighten-1" :content="'Current no assigned group'"></v-badge>
                  <v-btn small color="primary" @click="processingGroupDialog = true" style="transform: translateY(30px);">Assign</v-btn>
                </template>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action>
              <v-btn-toggle>
                <v-btn small @click="redirectTo('CustomPlanManagementPage', { id: currentShop.user.id })">
                  Custom Plan
                </v-btn>
                <v-btn small @click="redirectTo('StoreWebhooksManagementPage', {})">
                  Webhooks
                </v-btn>
                <v-btn small @click="redirectTo('ProductSyncConfigurationsPage', {})">
                  config
                </v-btn>
                <v-btn small @click="remoteAccessToUserAccount">
                  Login
                </v-btn>
              </v-btn-toggle>
            </v-list-item-action>
          </v-list-item>
        </v-card>
        <module-switches v-if="currentShop.store_type != 'source'"></module-switches>
      </v-col>
      <v-col cols="8">

        <div class="mb-4">
          <v-btn elevation="1" color="primary" small tile @click="redirectTo('Notes', {})">Notes</v-btn>
          <v-btn elevation="1" color="primary" small tile class="ml-2" @click="redirectTo('Tasks', {})">Tasks</v-btn>
          <v-btn elevation="1" color="primary" small tile class="ml-2" @click="updatePermission">Permission Update</v-btn>
          <v-btn elevation="1"  color="warning" small tile class="ml-2 float-right" @click="deleteSToreModal=true">Uninstall Store</v-btn>
        </div>

        <v-card :disabled="loading" :loading="loading" class="mx-auto pb-3">
          <v-card-text>
            <h2 class="pb-4 pt-1 d-flex align-center justify-space-between">
              <span>
                <v-icon>mdi-store</v-icon> Connected Partner Stores
              </span>
              <v-text-field @click:append="fetchShopData" dense label="Search stores" hide-details outlined v-model="searchStr" class="ml-15" append-icon="mdi-magnify">
              </v-text-field>
            </h2>
            <hr />
          </v-card-text>

          <div class="custom-pagination" v-if="pagination.total > 0">
            <v-pagination @input="paginationHandler($event)" v-model="shopConnectionCurrentPage" class="my-4" :length="pagination.total > 0 ? Math.ceil(pagination.total / 10) : 0" total-visible="5" circle />
          </div>

          <shops-connection-widget v-for="(connection, index) in currentShopConnections" v-bind:key="index"
            :parent-shop="currentShop" :shop-connection="connection">
          </shops-connection-widget>
        </v-card>
      </v-col>
      <v-snackbar v-model="copySuccess" :timeout="timeout" top color="#0E3B4D" elevation="0">
        {{ copyMessage }}
        <template v-slot:action="{ attrs }">
          <v-icon color="#fff" v-bind="attrs" @click="copySuccess = false">close</v-icon>
        </template>
      </v-snackbar>
    </v-row>


    <v-overlay :value="processingGroupDialog" class="dialog dialog-disconnect deleteStoreModal">
      <v-card light class="card-rounded not-a-link" width="500px">
        <v-toolbar height="71">
          <v-container class="px-0 d-flex justify-space-between">
            <v-card-title class="h3 pa-0">
              Assing processing group
            </v-card-title>
            <v-icon size="30px" color="#ffffff" class="mt-1" @click="processingGroupDialog = false">
              close
            </v-icon>
          </v-container>
        </v-toolbar>

        <div class="dialog-body pa-6">
          <div class="text-left">
            <v-select
              :items="processingGroups"
              label="Select Group"
              solo
              v-model="selectedProcessingGroup"
            ></v-select>
            <v-text-field label="Expired Time (In Seconds)" v-model="processingGroupExpiredTime"></v-text-field>

            <v-btn color="primary" @click="assignProcessingGroup(shopId)" :disabled="selectedProcessingGroup === ''">Assign</v-btn>
          </div>
        </div>
      </v-card>
    </v-overlay>

    <v-overlay :value="deleteSToreModal" class="dialog dialog-disconnect deleteStoreModal">
      <v-card light class="card-rounded not-a-link" width="625px">
        <v-toolbar height="71">
          <v-container class="px-0 d-flex justify-space-between">
            <v-card-title class="h3 pa-0">
              Are you sure? you want to delete this store.
            </v-card-title>
            <v-icon size="30px" color="#ffffff" class="mt-1" @click="deleteSToreModal = false, confirmation = ''">
              close
            </v-icon>
          </v-container>
        </v-toolbar>

        <div class="dialog-body pa-7">
          <div class="text-left">
            <h4 class="h4 mb-5">Things to check before deleting the store</h4>
            <!--            <p class="list-title">Check first:</p>-->
            <ol class="px-0 inside-decimal">
              <li>Store is uninstalled by customer</li>
              <li>Customer having issue reinstalling syncio</li>
              <li>Soft delete didn't work by webhook</li>
            </ol>
          </div>
          <div class="pb-0 col col-12 pt-7 pb-7 pl-0 pr-0 ">
            <div
              class="v-input v-input--is-label-active v-input--is-dirty v-input--dense theme--light v-text-field v-text-field--is-booted v-text-field--enclosed v-text-field--outlined">
              <div class="v-input__control">
                <div class="v-input__slot">
                  <fieldset aria-hidden="true">
                    <legend style="width: 165px;"><span>&ZeroWidthSpace;</span></legend>
                  </fieldset>
                  <div class="v-text-field__slot">
                    <label for="input-160" class="v-label v-label--active theme--light"
                      style="left: 0px; right: auto; position: absolute;">Type <b>DELETE</b> here to confirm </label>
                    <input id="input-160" type="text" v-model="confirmation">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="twoColumnButton">
            <v-btn height="45px" width="100%" class="btn btn-primary mb-2"
              @click="deleteSToreModal = false, confirmation = ''">Cancel</v-btn>
            <v-btn height="45px" width="100%" class="btn btn-primary mb-2" :disabled="confirmation !== 'DELETE'"
              @click="deleteStore">Delete</v-btn>
          </div>

        </div>
      </v-card>
    </v-overlay>
    <v-overlay :value="updatePermissionModal" class="dialog dialog-disconnect deleteStoreModal">
      <v-card light class="card-rounded not-a-link" width="625px">
        <v-toolbar height="71">
          <v-container class="px-0 d-flex justify-space-between">
            <v-card-title class="h3 pa-0">
           Generated URL
            </v-card-title>
            <v-icon size="30px" color="#ffffff" class="mt-1" @click="updatePermissionModal = false, confirmation='' " >
              close
            </v-icon>
          </v-container>
        </v-toolbar>

        <div class="dialog-body pa-7">
          <div class="text-left">
            <h4 class="h4 mb-5">Below generated URL will update permission & refresh token for this store.</h4>
            <p class="copiable_content_box">"{{generatedURL}}"</p>
            <div style=" display: block; text-align: right; padding-right: 5px;">
              <button class="ml-5 copy-btn" type="button" v-clipboard:copy="generatedURL" v-clipboard:success="()=> copySuccess = true">Copy</button>
            </div>
          </div>
        </div>
      </v-card>
    </v-overlay>

  </v-container>
</template>

<script>
import {
  FETCH_CURRENT_SHOP,
  FETCH_CURRENT_SHOP_CONNECTIONS,
  DELETE_STORE,
  UPDATE_PERMISSION
} from "@/store/actions.type";
import {
  SET_CURRENT_SHOP_CONNECTIONS,
} from "@/store/mutations.type";
import { mapGetters, mapState } from "vuex";
import { VUE_APP_ID, VUE_APP_REMOTE_ACCESS_URL } from "@/common/config";
import ShopsConnectionWidget from "@/views/components/ShopsConnectionWidget";
import JwtService from "@/common/jwt.service";
import StoreProductCounter from './components/StoreProductCounter';
import ModuleSwitches from './components/ModuleSwitches';
import FetchProductTrigger from './components/FetchProductTrigger';
import axios from 'axios';
import { createHelpers } from 'vuex-map-fields';
const { mapFields } = createHelpers({
  getterType: 'shops/getField',
  mutationType: 'shops/updateField',
});

let storeDeleted;

export default {
  name: "SingleStorePage",
  components: {
    ShopsConnectionWidget,
    StoreProductCounter,
    ModuleSwitches,
    FetchProductTrigger,
  },
  data: function () {
    return {
      loading: true,
      shopId: null,
      deleteSToreModal: false,
      confirmation:'',
      generatedURL:'',
      updatePermissionModal: false,
      copyMessage: 'URL copied to the clipboard!',
      copySuccess: false,
      timeout:2000,
      shopConnectionCurrentPage: 1,
      selectedProcessingGroup: '',
      processingGroupExpiredTime: null,
      processingGroupDialog: false
    };
  },
  async created() {
    this.shopId = this.$route.params.id;
    this.$store.commit(`shops/${SET_CURRENT_SHOP_CONNECTIONS}`, {});
    await this.fetchShopData();
    const response = await axios("networks/assign-processing-groups")
    this.jailedStoresList = response.data.list
    this.processingGroups = response.data["processing-group"]
  },
  computed: {
    ...mapGetters("shops", [
      "shops",
      "currentShop",
      "currentShopConnections",
    ]),
    ...mapState("shops",[
      "pagination"
    ]),
    ...mapFields([
      "jailedStoresList",
      "processingGroups"
    ])
  },
  methods: {
    async removeProcessingGroup(id) {
      await axios.delete(`networks/assign-processing-groups/${id}`)
      this.fetchShopData(this.shopConnectionCurrentPage)
    },
    async assignProcessingGroup(id) {
      let expired = null;
      if(this.processingGroupExpiredTime) {
        expired = +this.processingGroupExpiredTime
      }
      await axios.post(`networks/assign-processing-groups/${id}`, {
        'processing-group': this.selectedProcessingGroup,
        'expired': expired
      })
      this.processingGroupDialog = false
      this.fetchShopData(this.shopConnectionCurrentPage)
    },
    fetchShopData(page = this.shopConnectionCurrentPage) {
      this.loading = true;
      this.$store
        .dispatch(`shops/${FETCH_CURRENT_SHOP}`, this.shopId)
        .finally(() => {
          this.$store
            .dispatch(`shops/${FETCH_CURRENT_SHOP_CONNECTIONS}`, { shopId: this.shopId, limiter: 10, search_str: this.searchStr ?? '', page: page })
            .finally(() => {
              this.loading = false;
            });
        });
    },
    paginationHandler(page) {
      this.fetchShopData(page)
    },
    remoteAccessToUserAccount() {
      let token = JwtService.getToken();
      let appId = VUE_APP_ID;
      let redirectUrl =
        VUE_APP_REMOTE_ACCESS_URL +
        "remote/login?aid=" +
        appId +
        "&token=" +
        token +
        "&store=" +
        this.currentShop.store_domain;
      window.open(redirectUrl, "_blank");
    },
    redirectTo(routeName, param) {
      this.$router.push({ name: routeName, param: param });
    },
    updatePermission(){
      this.loading = true;
      const store_name=this.currentShop.store_domain
      this.$store.dispatch(`shops/${UPDATE_PERMISSION}`, store_name).then((data)=>{
        if(data.success){
          this.generatedURL=data.URL;
          this.updatePermissionModal=true;
          this.loading = false;
        }else{
          this.loading = false;
          alert(data.errors[0]);
        }
      }).catch((error)=>{
        this.loading = false;
        alert(error.errors[0]);
      });
    },
    deleteStore() {
      const store_id = this.shopId
      this.$store.dispatch(`shops/${DELETE_STORE}`, store_id).then((data) => {

        this.$router.push({ name: "Dashboard", storeDeleted });
      });
    },
  },
};
</script>

<style lang="scss">
.store-type {
  .destination {
    color: #ff8683;
    font-weight: 700;
  }

  .source {
    color: #fbb13f;
    font-weight: 700;
  }
}

.v-application {
  .custom-pagination {
    > nav {
      margin-top: 0 !important;
    }

    .v-pagination__item,
    .v-pagination__navigation {
      border: 1px solid #E7E7E7 !important;
      box-shadow: 0px 2px 2px 0px #0000001a !important;
      width: 24px !important;
      height: 24px !important;
      border-radius: 4px;
      padding: 0 !important;
      font-size: 14px !important;
    }

    .v-pagination {
      justify-content: flex-end;
    }

    .v-pagination__item--active {
      cursor: not-allowed;
      pointer-events: none;
    }
  }
}
</style>
<style>
.deleteStoreModal .v-toolbar__content {
  background: #0E3B4D;
  padding: 0 40px;
  border-radius: 20px 20px 0 0;
  letter-spacing: 0 !important;
}

.deleteStoreModal .v-card__title.h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: #fff;
  letter-spacing: 0;
  padding: 0;
}

.deleteStoreModal p.list-title,
ol {
  line-height: 28px !important;
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
}

.deleteStoreModal h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
}

.deleteStoreModal .v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: none;
}

.deleteStoreModal .dialog-body.pa-7 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}

.deleteStoreModal .dialog-body.pa-7 ul,
.deleteStoreModal .dialog-body.pa-7 ol {
  padding-left: 15px !important;
}

.deleteStoreModal .twoColumnButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteStoreModal .twoColumnButton button {
  background: #0E3B4D !important;
  width: 49% !important;
  border-radius: 4px;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.4px;
  padding: 0 16px !important;
  transition: 0.25s background;
}


  .deleteStoreModal .v-sheet.v-card {
    border-radius: 20px;
  }
  .copy-btn {

    border: 1px solid #0E3B4D;
    font-size: 14px;
    border-radius:5px ;
    /*padding: px;*/
    padding: 5px 12px;
  }
  .copy-btn:hover {
     background: #0E3B4D;
     color: #fff;
   }

  .copiable_content_box{
    padding: 14px;
    border-radius: 7px;
    background: rgb(14 59 77 / 10%);
    box-shadow: inset 0 0 20px 4px rgb(0 0 0 / 9%);
  }

</style>
