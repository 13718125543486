<template>
<div>
    <div class="mt-1">
        <span class="text-capitalize">{{ currentShop.platform }}</span> has products
        <v-badge
            v-if="platformCounter != null"
            class="ml-2"
            color="blue"
            :content="platformCounter">
        </v-badge>
        <v-badge v-else
            color="red"
            class="ml-2"
            content="E"
        ></v-badge>
    </div>
    <div v-if="currentShop.store_type === 'source'" class="mt-2">
        Syncio fetched 
        <v-badge
            v-if="syncioCounter != null"
            class="ml-2"
            :content="syncioCounter">
        </v-badge>
        <v-badge v-else
            color="red"
            class="ml-2"
            content="E"
        ></v-badge>
        <v-tooltip 
            v-if="syncioCounter != null && platformCounter != null && syncioCounter > platformCounter"
            right
        >
            <template v-slot:activator="{ on, attrs }">
                <v-icon 
                    v-on="on"
                    v-bind="attrs"
                    @click="requestDuplicateFetchProductCleanup()"
                    class="float-right"
                    small
                >
                    mdi-ghost-off-outline
                </v-icon>
            </template>
            <span>Clean up duplicate fetch products</span>
        </v-tooltip>
    </div>
</div>
</template>
<script>
import { mapState } from "vuex";
import { ShopApiService } from "@/common/api.service";

export default {
    name: "StoreProductCounter",
    data: function() {
        return {
            platformCounter: null,
            syncioCounter: null,
        };
    },
    created() {
        this.loadCounters();
    },
    watch: {
        currentShop: function (newCS, oldCS) {
            if (newCS.id != oldCS.id) {
                this.platformCounter = null;
                this.syncioCounter = null;
                this.loadCounters();
            }
        }
    },
    computed: {
        ...mapState("shops", ['currentShop']),
    },
    methods: {
        loadCounters() {
            let shopId = this.$route.params.id;
            ShopApiService.getProductCounters(shopId)
                .then(({data}) => {
                    if (data.success) {
                        this.platformCounter = data.platform_counter;
                        this.syncioCounter = data.syncio_counter;
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        requestDuplicateFetchProductCleanup() {
            let shopId = this.$route.params.id;
            ShopApiService.fetchProductCleanup(shopId)
                .then(({data})=> {
                    if (data.success) {
                        alert('Fetch products clean up task started.');
                    } else {
                        alert('Failed to start fetch products clean up task!');
                    }
                })
                .catch((error) => {
                    console.log(error);
                    alert('Failed to start fetch products clean up task!');
                });
        }
    }
};
</script>